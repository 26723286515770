.banner {
    padding: 2rem 0;
    color: #fff;
    background: #F49B05;
}

.rating-display-mobile {
    display: none;
}

.banner-text {
    text-align: center;
}

.banner-action {
    margin-top: 10px;
}

.banner-button {
    color: #000;
    text-transform: uppercase;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    background: var(--secondary-main);
    padding: 0.75rem 0.75rem;
    font-weight: 500;
    text-align: center;
}

.banner-button:hover {
    background: var(--secondary-hex-light);
    border-radius: 16px;
    transition: all 250ms ease;
    color: #fff;
}

.banner-button-link {
    color: #000;
}

.rating-item {
    text-align: center;
    padding: 2px;
}

.banner-button-dialog {
    color: #fff;
    text-transform: uppercase;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    background: var(--primary-main);
    padding: 1rem;
    margin: 10px;
    font-weight: 500;
    text-align: center;
}
.banner-circular-spinner {
    margin: 10px;
}

.dialog-actions {
    justify-content: center !important;
}

.flex-container {
    display: flex;
    justify-content: center;
    gap: 50px;
}

@media (max-width: 600px) {
    .banner-text {
        padding: 10px 20px;
    }
    .banner-action {
        margin: 0 20px ;
    }

    .rating-display-web {
        display: none;
    }

    .rating-display-mobile {
        display: inline;
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
    }
}
