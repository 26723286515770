.intro {
  padding-top: 3rem;
  background: var(--green-hex-light);
}

.intro-wrapper {
  position: relative;
  background: url('./../../images/intro/cover.jpeg') center center/cover no-repeat;
  height: 500px;
  margin: auto auto;
  overflow: hidden;
}

.intro-logo {
  margin: auto;
  width: 320px;
  height: 240px;
  display: flex;
  top: 80px;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.intro-light-background {
  position: absolute;
  display: block;
  top: 50px;
  bottom: 50px;
  left: calc(60% - 500px);
  right: calc(60% - 500px);
  background: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {

  .intro-wrapper {
    position: relative;
    background: url('./../../images/intro/cover.jpeg') center center/cover no-repeat;
    height: 250px;
    margin: auto auto;
    overflow: hidden;
  }

  .intro-logo {
    margin: auto;
    width: 160px;
    height: 120px;
    display: flex;
    top: 20px;
    position: relative;
  }

  .intro-light-background {
    position: absolute;
    display: block;
    left: calc(60% - 175px);
    right: calc(60% - 175px);
    border-radius: 10px;
  }
}