.thumbs-swiper swiper-slide {
  opacity: 0.4;
}

.thumbs-swiper .swiper-slide-thumb-active {
  opacity: 1;
}

.thumbs-swiper {
  background: black;
}


.full-screen-carousel {
  width: 50%;
  height: 80%;
}

@media (max-width: 600px) {
  .full-screen-carousel {
    width: 100%;
    height: 80%;
  }
}
// TODO add inline styles from full screen carousel here, add breakpoints for mobile viewing
