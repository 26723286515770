.inscription-card {
    padding: 4rem;
    background: rgba(157, 201, 130, 0.4);
}

.inscription-button-group-selected {
    color: var(--secondary-main) !important;
    background-color: var(--primary-main) !important;
}

.inscription-button-container {
    color: #ffffff;
    display: flex;
    float: right;
}


.inscription-button {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: var(--green-hex-main);
    padding: 6px 16px;
    margin: 10px;
    font-weight: 500;
    text-align: center;
}

.inscription-button:hover {
    transition: all 250ms ease;
    font-weight: bold;
}

.inscription-confirmation-button {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: var(--green-hex-main);
    padding: 6px 16px;
    margin: auto auto;
    width: 300px;
    font-weight: 500;
    text-align: center;
}

.inscription-stepper {
    padding: 20px 0;
}

.inscription-confirmation-error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.inscription-confirmation-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    flex-direction: column;
}

.inscription-confirmation-card-header {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
    background: rgb(249, 148, 32);
    box-shadow: none;
    padding: 10px 0;
}

.inscription-confirmation-card {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-left: 10px solid var(--primary-main);
    margin: 50px 0;
}

.inscription-confirmation-card-content {
    margin: 15px;
    overflow-y: auto;
}

.inscription-form-title {
    margin: 10px 0 30px 10px;
}

.inscription-dialog {
    text-decoration: underline !important;
    display: inline-block;
    color: blue;
    cursor: pointer;
}

.inscription-wait-list-card {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border: 10px solid var(--primary-main);
    margin: 25px 50px;
}

.inscription-wait-list-card-header {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background: rgb(249, 148, 32);
    box-shadow: none;
}

@media (max-width: 600px) {

    .inscription-stepper {
        padding: 10px 0;
        flex-direction: column !important;
        text-align: left;
        align-items: flex-start !important;
    }
}