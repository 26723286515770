.flex-container {
    display: flex;
    justify-content: center;
}

.attestation-button {
    color: #ffffff;
    display: flex;
    width: auto;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: var(--green-hex-main);
    padding: 20px;
    margin: 10px;
    font-weight: 500;
    text-align: center;
    flex-direction: row;
    font-size: 24px;
}

.attestation-button:hover {
    transition: all 250ms ease;
    font-weight: bold;
}

.attestation-confirmation-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.attestation-confirmation-card {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-left: 10px solid var(--primary-main);
    margin: 50px 0;
}

.attestation-confirmation-card-content {
    overflow-y: auto;
    margin: 15px;
}

.attestation-form-title {
    margin: 30px 0;
}
