.image-grid {
    margin-top: -40px;
}

.vacations-grid {
    margin-top: 20px;
}

.vacations-title {
    margin-top: 25px;
}

.vacations-content {
    font-size: 16px;
    text-align: justify;
    margin: 1rem 0;
    line-height: 1.1;
    color: inherit;
}

.vacation-swiper-slide-card {
    cursor: pointer;
    position: relative;
    margin: 60px 20px;
}

.vacation-swiper-slide-card-img {
    display: block;
    width: 100%;
}

.tab-div {
    display: inline-block;
    margin-left: 2rem;
}

.vacations-tabs {
    color: #fff;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: var(--primary-hex-light);
    padding: 0.75rem 1.5rem;
}

.vacations-tabs:hover {
    background: var(--primary-main);
    color: var(--secondary-main);
    border-radius: 8px;
    transition: all 250ms ease;
}

.active {
    background: var(--primary-main);
}

.vacations-details-button-container {
    display: flex;
    justify-content: center;
}

.vacations-details-button {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 10px 10px;
    background: rgba(157, 201, 130, 1);
    border-radius: 15px;
    cursor: pointer;
    text-transform: uppercase;
}


@media (max-width: 600px) {

    .vacations-section {
        padding-left: 0;
        padding-right: 0;
    }

    .tab-div {
        margin-left: 0;
    }

    .vacations-tabs {
        border-radius: 0;
        overflow: hidden;
        cursor: pointer;
        background: var(--primary-hex-light);
        padding: 0.75rem 1.35rem;
        transition: none;
    }

    .vacations-tabs:hover {
        border-radius: 0;
        overflow: hidden;
        cursor: pointer;
        background: var(--primary-main);
    }

    .vacations-content {
        text-align: left;
        margin-top: 0px;
    }

    .image-grid {
        margin-top: 0;
    }

}

