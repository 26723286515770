@font-face {
    font-family: 'Big City Grotesque';
    src: local('Big City Grotesque Regular'), local('Big-City-Grotesque-Regular'),
    url('resources/assets/BigCityGrotesquePro-Regular.woff2') format('woff2'),
    url('resources/assets/BigCityGrotesquePro-Regular.woff') format('woff'),
    url('resources/assets/BigCityGrotesquePro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Root */
:root {
    --primary-main: rgba(244, 155, 5, 1);
    --primary-hex-main: rgba(244, 155, 5, 1);
    --primary-hex-light: rgba(244, 155, 5, 0.5);
    --secondary-main: #ffffff;
    --secondary-hex-main: rgba(255, 255, 255, 1);
    --secondary-hex-light: rgba(255, 255, 255, 0.5);
    --green-hex-main: rgba(157, 201, 130, 1);
    --green-hex-light: rgba(157, 201, 130, 0.4);
    --text-muted: #9DC982;
    --swiper-navigation-color: var(--primary-main);
    --swiper-pagination-top: 10px;
    --swiper-pagination-fraction-color: var(--primary-main);
    --topbar-mobile-width: 220px;
}
.swiper-button svg {
    width: 1.5rem;
    height: 1.5rem;
}

body {
    font-family: 'Big City Grotesque', serif !important;
    margin: 0;
}

/*Global */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5rem;
    line-height: 1.2;
    color: inherit;
}

p {
    margin: 1rem 0;
    line-height: 1.1;
    color: inherit;
}

a {
    text-decoration: none !important;
}

.container {
    padding: 0 1rem;
    max-width: 1170px;
    margin: 0 auto;
}

.card-container {
    padding: 2rem;
}

.card-container:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
}

.card:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
}

.card-title {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: normal;
}

.card-subtitle {
    font-size: 0.875rem;
    color: var(--text-muted);
}

.section {
    padding: 3rem;
    background: var(--green-hex-light);
}

.section-header {
    margin: 16px 0 0 0;
}

.section-header h3 {
    font-weight: 700;
}

.section-header p {
    font-size: 16px;
    max-width: 36rem;
}

.expand {
    margin-left: auto;
}

.rotated {
    transform: rotate(180deg);
}

@media (max-width: 600px) {

    .container {
        padding: 0;
    }

    .card-container {
        padding: 1rem;
    }

    .section {
        padding-left: 0;
        padding-right: 0;
    }
}

swiper-container:focus-visible {
    outline: none;
}
